<template>
  <div class="container content-p">
    <section class="account-add-wrapper">
      <b-row class="account-add">
        <!-- Col: Left (Account Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-form @submit.prevent>
            <b-card no-body class="account-preview-card">
              <!-- Account Client & Payment Details -->
              <b-card-body class="account-padding">
                <h6 class="mb-1">Additional Information</h6>
                <b-row class="account-spacing">
                  <!-- Col: Pathway Name -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Course Name</label>
                    <b-form-input id="program-name" v-model="accountData.program.name" placeholder="CompTIA Security+" />
                  </b-col>

                  <!-- Col: Program Start Date -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Program Start</label>
                    <b-form-datepicker
                      class="w-100"
                      id="program-date-start"
                      :value="accountData.program.startDate"
                      @input="(val) => (accountData.program.startDate = val)"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      locale="en"
                    />
                  </b-col>

                  <!-- Col: Last Date of Attendance -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Program End</label>
                    <b-form-datepicker
                      class="w-100"
                      id="program-date-end"
                      :value="accountData.program.endDate"
                      @input="(val) => (accountData.program.endDate = val)"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      locale="en"
                    />
                  </b-col>

                  <b-col cols="12" lg="9" md="8" sm="12" class="mb-lg-1">
                    <label>Additional Comments</label>
                    <b-form-input id="account-data-sales-person" v-model="accountData.comments" />
                  </b-col>

                  <b-col cols="12" lg="3" md="4" sm="12" class="mb-lg-1">
                    <label>Status Override</label>
                    <v-select
                      v-model="accountData.status"
                      :options="statusOptions"
                      :clearable="false"
                      :reduce="(val) => val.value"
                      required
                      input-id="account-status"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

            <b-card>
              <b-card-body class="p-0">
                <b-card-title>
                  Payments

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewPaymentInItemForm"
                    class="float-right"
                  >
                    Add Payment
                  </b-button>
                </b-card-title>

                <div ref="formPmnt" class="repeater-form" :style="{ height: trHeight['pmnt'] || 'auto' }">
                  <b-row v-for="(item, index) in payments" :key="index" ref="rowPmnt" class="pb-2">
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="2"> Date </b-col>
                          <b-col cols="12" :lg="lastOptionsSelected === 'description' ? 4 : 2"> Description </b-col>
                          <b-col cols="12" lg="2"> Amount </b-col>
                          <b-col cols="12" lg="2"> Paid At </b-col>
                          <b-col cols="12" lg="2"> Type </b-col>
                          <b-col cols="12" lg="2"> Status </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Date</label>
                            <b-form-datepicker
                              class="w-100"
                              :id="`account-data-payment-${index}-date`"
                              :value="item.date"
                              @input="(val) => (item.date = val)"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              locale="en"
                              placeholder="n/a"
                              reset-button
                            />
                          </b-col>
                          <b-col cols="12" :lg="lastOptionsSelected === 'description' ? 4 : 2">
                            <label class="d-inline d-lg-none">Description</label>
                            <b-form-input
                              @focusin="lastOptionsSelected = 'description'"
                              @focusout="lastOptionsSelected = undefined"
                              :id="`account-data-payment-${index}-description`"
                              v-model="item.description"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Amount</label>
                            <b-input-group prepend="$">
                              <cleave
                                :id="`account-data-payment-${index}-amount`"
                                v-model="item.amount"
                                class="form-control"
                                :raw="true"
                                :options="options.number"
                                placeholder="0"
                              />
                            </b-input-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Paid At</label>
                            <b-form-datepicker
                              class="w-100 text-nowrap"
                              :id="`account-data-payment-${index}-paidAt`"
                              :value="item.paidAt"
                              @input="(val) => (item.paidAt = val)"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              locale="en"
                              placeholder="n/a"
                              reset-button
                              :state="item.status === 'paid' && !item.paidAt ? false : null"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Type</label>
                            <v-select
                              v-model="item.type"
                              :options="paymentTypeOptions"
                              placeholder="Payment Type"
                              :id="`account-data-payment-${index}-type`"
                              :reduce="(val) => val.value"
                              :clearable="false"
                              @input="initTrHeightPayment"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Status</label>
                            <v-select
                              v-model="item.status"
                              :options="paymentStatusOptions"
                              placeholder="Payment Status"
                              :id="`account-data-payment-${index}-status`"
                              :reduce="(val) => val.value"
                              :clearable="false"
                              @input="(val) => initTrHeightPayment(val, index)"
                            />
                          </b-col>

                          <b-col v-if="item.status === 'refunded'" cols="12" lg="5" class="mt-lg-1">
                            <label class="d-inline d-lg-none">Refund Note</label>
                            <b-form-textarea
                              no-resize
                              placeholder="Refunded overpay on 2 payments"
                              :id="`account-data-payment-${index}-note`"
                              v-model="item.note"
                            />
                          </b-col>
                          <b-col v-else-if="item.status === 'credited'" cols="12" lg="9" class="mt-lg-1">
                            <label class="d-inline d-lg-none">Credit Note</label>
                            <b-form-textarea
                              no-resize
                              placeholder="Credit "
                              :id="`account-data-payment-${index}-note`"
                              v-model="item.note"
                            />
                          </b-col>

                          <b-col
                            v-if="item.status !== 'unpaid'"
                            cols="12"
                            lg="3"
                            :offset-lg="
                              item.status === 'refunded' || item.status === 'credited'
                                ? 0
                                : item.paidBy === 'company'
                                ? item.paidVia === 'check'
                                  ? 1
                                  : 3
                                : item.paidVia === 'check'
                                ? 5
                                : 7
                            "
                            class="mt-lg-1"
                          >
                            <label class="d-inline d-lg-none">Payment Method</label>
                            <v-select
                              v-model="item.paidVia"
                              :options="paymentMethodOptions"
                              placeholder="Credit Card"
                              :id="`account-data-payment-${index}-paidVia`"
                              :reduce="(val) => val.value"
                              :clearable="true"
                            />
                          </b-col>
                          <b-col v-if="item.status !== 'unpaid' && item.paidVia === 'check'" cols="12" lg="2" class="mt-lg-1">
                            <label class="d-inline d-lg-none">{{ title(item.paidVia) }} #</label>
                            <b-form-input
                              type="number"
                              maxlen="12"
                              placeholder="#5861"
                              :id="`account-data-payment-${index}-paidViaId`"
                              v-model="item.paidViaId"
                            />
                          </b-col>
                          <b-col
                            v-if="item.status !== 'unpaid' && item.paidVia !== 'scholarship' && item.status !== 'credited'"
                            cols="12"
                            lg="2"
                            class="mt-lg-1"
                          >
                            <label class="d-inline d-lg-none">Paid By</label>
                            <v-select
                              v-model="item.paidBy"
                              :options="paymentPayerOptions"
                              :id="`account-data-payment-${index}-paidBy`"
                              placeholder="Paid By"
                              :reduce="(val) => val.value"
                              :clearable="true"
                            />
                          </b-col>
                          <b-col v-if="item.paidBy === 'company'" cols="12" lg="4" class="mt-lg-1">
                            <label class="d-inline d-lg-none">Name</label>
                            <b-form-input
                              placeholder="Company Name"
                              :id="`account-data-payment-${index}-paidByName`"
                              v-model="item.paidByName"
                            />
                          </b-col>
                        </b-row>

                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removePaymentItem(index)" />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>

            <b-card>
              <b-card-body class="p-0">
                <b-card-title>
                  Charges

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewChargeInItemForm"
                    class="float-right"
                  >
                    Add Charge
                  </b-button>
                </b-card-title>

                <div ref="formChrg" class="repeater-form" :style="{ height: trHeight['chrg'] || 'auto' }">
                  <b-row v-for="(item, index) in charges" :key="index" ref="rowChrg" class="pb-2">
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="2"> Date </b-col>
                          <b-col cols="12" lg="8"> Description </b-col>
                          <b-col cols="12" lg="2"> Amount </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Date</label>
                            <b-form-datepicker
                              class="w-100"
                              :id="`account-data-charge-${index}-date`"
                              :value="item.date"
                              @input="(val) => (item.date = val)"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              locale="en"
                            />
                          </b-col>
                          <b-col cols="12" lg="8">
                            <label class="d-inline d-lg-none">Description</label>
                            <b-form-input :id="`account-data-charge-${index}-description`" v-model="item.description" />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Amount</label>
                            <b-input-group prepend="$">
                              <cleave
                                :id="`account-data-charge-${index}-amount`"
                                v-model="item.amount"
                                class="form-control"
                                :raw="true"
                                :options="options.number"
                                placeholder="0"
                              />
                            </b-input-group>
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeChargeItem(index)" />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="account-actions mt-md-0 mt-2">
          <!-- Action Buttons -->
          <b-card>
            <!-- Button: Print -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" block @click="updateAccount" :disabled="!isLoading">
              Save Changes
            </b-button>

            <!-- Button: Send Invoice -->
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" class="mb-75" block @click="$router.back()">
              &leftarrow; Return
            </b-button>

            <hr class="my-1" />

            <!-- Button: Apply Template -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              block
              @click="applyTemplate"
              :disabled="!isLoading"
            >
              Apply Template
            </b-button>
          </b-card>

          <b-col>
            <statistic-card-horizontal
              fa-icon="fa-solid fa-dollar-sign"
              :statistic="money(totalDue)"
              statistic-title="Total Due"
              color="info"
            />
          </b-col>
          <b-col>
            <statistic-card-horizontal
              fa-icon="fa-solid fa-dollar-sign"
              :statistic="money(totalPaid)"
              statistic-title="Total Paid"
              color="success"
            />
          </b-col>
          <b-col>
            <statistic-card-horizontal
              fa-icon="fa-solid fa-dollar-sign"
              :statistic="money(totalBalance)"
              statistic-title="Remaining Balance"
              color="secondary"
            />
          </b-col>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue';
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { dynamicHeightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BPopover,
  BRow,
  VBToggle,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import router from '@/router';
import FormDatepicker from '@/views/forms/form-element/form-datepicker/FormDatepicker.vue';
import FormDatepickerBasic from '@/views/forms/form-element/form-datepicker/FormDatepickerBasic.vue';

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import useAccountsList from '@/views/apps/accounting/receivable/account/list/useAccountsList';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import { useToast } from 'vue-toastification/composition';
import { title } from '@core/utils/filter';

export default {
  components: {
    Cleave,
    FormDatepickerBasic,
    FormDatepicker,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    BPopover,
    BCardTitle,
    BCardHeader,
    flatPickr,
    vSelect,
    Logo,
    StatisticCardHorizontal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [dynamicHeightTransition],
  destroyed() {
    window.removeEventListener('resize', this.initTrHeightPayment);
    window.removeEventListener('resize', this.initTrHeightCharge);
  },
  watch: {
    accountData(v) {
      this.initTrHeightPayment();
      this.initTrHeightCharge();
    },
    lastOptionsSelected(v) {
      this.initTrHeightPayment();
      this.initTrHeightCharge();
    },
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },

    addNewPaymentInItemForm() {
      this.accountData.payments.push({ ...this.itemFormBlankPayment });
      this.initTrHeightPayment();
    },
    removePaymentItem(index) {
      this.accountData.payments.splice(index, 1);
      this.initTrHeightPayment();
    },
    initTrHeightPayment(val, index) {
      this.trSetHeight('pmnt', null);
      this.$nextTick(() => {
        this.trSetHeight('pmnt', this.$refs.formPmnt.scrollHeight);
      });

      if (val === 'credited') {
        this.accountData.payments[index].paidVia = 'toc';
      }
    },
    addNewChargeInItemForm() {
      this.accountData.charges.push({ ...this.itemFormBlankCharge });
      this.initTrHeightCharge();
    },
    removeChargeItem(index) {
      this.accountData.charges.splice(index, 1);
      this.initTrHeightCharge();
    },
    initTrHeightCharge() {
      this.trSetHeight('chrg', null);
      this.$nextTick(() => {
        this.trSetHeight('chrg', this.$refs.formChrg.scrollHeight);
      });
    },

    initiateAccountEdit() {
      console.log(this.accountData);

      // Remove fields that could cause issues and are automatically calculated
      delete this.accountData.balance;

      // Remove fields that could cause issues and are automatically calculated
      delete this.accountData.balance;

      this.$http
        .patch(`accounts/${this.accountId}`, this.accountData)
        .then((response) => {
          if (response.status === 200) {
            const { id } = response.data;

            this.$toast({
              component: ToastificationContent,

              props: {
                title: 'Account Updated',
                icon: 'ArchiveIcon',
                variant: 'success',
                text: 'Your account has successfully been modified!',
              },
            });

            this.$router.push({ name: 'apps-account-preview', params: { id } });
          }

          console.log(response);
        })
        .catch((e) => {
          console.error(e);
        });

      console.log(this.accountData);
    },
  },
  data() {
    return {
      accountId: this.$router.currentRoute.params.id,

      // accountData: {
      //   charges: {},
      //   paymentPlan: {},
      //   payments: [],
      //   status: 'graduated',
      // },
      paymentDetails: {},
      studentStatusOptions: [
        { value: 'graduated', text: 'Graduated' },
        { value: 'terminated', text: 'Terminated' },
        { value: 'withdrew', text: 'Withdrew' },
      ],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },

      lastOptionsSelected: '',
    };
  },
  setup() {
    const { paymentStatusOptions, paymentTypeOptions, paymentMethodOptions, paymentPayerOptions, statusOptions } = useAccountsList();
    const toast = useToast();

    const itemFormBlankCharge = {
      date: new Date(),
      description: 'Manual Charge',
      amount: 0,
    };

    const itemFormBlankPayment = {
      date: undefined,
      description: 'Manual Payment',
      amount: 0,
      status: 'unpaid',
    };

    const isLoading = ref(false);
    const accountData = ref({ charges: [], payments: [], program: {} });
    const studentData = ref({});
    const profileData = ref({});
    const cohortData = ref({});

    const charges = computed(() => {
      return accountData.value.charges.sort((a, b) => {
        return moment(a.date).toDate() - moment(b.date).toDate();
      });
    });

    const payments = computed(() => {
      return accountData.value.payments.sort((a, b) => {
        return moment(a.date).toDate() - moment(b.date).toDate();
      });
    });

    const fetchAccount = async () => {
      isLoading.value = true;
      store
        .dispatch('receivableStoreModule/fetchAccount', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'student profile cohort' },
        })
        .then((response) => {
          const { data } = response;
          if (!data.program) data.program = {};
          accountData.value = data;
          studentData.value = data.student;
          profileData.value = data.profile;
          cohortData.value = data.cohort;

          /// for (let i = 0; i < accountData.value.payments.length; i++) {
          ///   initTrHeightPayments
          /// }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              accountData.value = {};
            }

            console.error('Failed to fetch account', error);
            return;
          }

          console.error(error);
        });
    };

    const applyTemplate = function () {
      this.$swal({
        title: 'Are you sure?',
        text: 'This will remove all payments and charges, then apply the most applicable template to this account',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reset & Apply',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const ad = accountData.value;
          isLoading.value = true;

          store
            .dispatch('receivableStoreModule/applyTemplateToAccount', {
              accountId: ad.id,
            })
            .then(async (response) => {
              const { data } = response;
              // accountData.value = data;

              toast({
                component: ToastificationContent,

                props: {
                  variant: 'success',
                  text: 'Successfully saved changes to account',
                },
              });

              location.reload();
            })
            .catch((error) => {
              if (error.response) {
                toast({
                  component: ToastificationContent,

                  props: {
                    variant: 'danger',
                    title: 'Failed to apply template',
                    text: error.response.data.message,
                  },
                });
                return;
              }

              toast({
                component: ToastificationContent,

                props: {
                  variant: 'danger',
                  title: 'Failed to apply template',
                  text: 'An unknown error occurred',
                },
              });
            });
        }
      });
    };

    const updateAccount = async () => {
      const ad = accountData.value;

      for (const pmt of ad.payments) {
        if (pmt.status === 'paid' && pmt.paidAt && new Date(pmt.paidAt) > new Date()) {
          return toast({
            component: ToastificationContent,
            props: {
              title: 'Please check account payments',
              text: 'Payments (in paid state) cannot be set in the future',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        } else if (pmt.amount <= 0 && pmt.status !== 'credited') {
          return toast({
            component: ToastificationContent,
            props: {
              title: 'Please check account payments',
              text: 'Payments must be > $0.00',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }

      isLoading.value = true;

      const payload = {
        charges: ad.charges,
        payments: ad.payments.map((payment) => {
          if (!payment.paidVia) payment.paidVia = undefined;
          if (!payment.paidBy) payment.paidBy = undefined;
          return payment;
        }),
        program: ad.program,
        comments: ad.comments,
        status: ad.status,
        legacy: true,
      };

      store
        .dispatch('receivableStoreModule/updateAccount', {
          id: router.currentRoute.params.id,
          accountData: payload,
        })
        .then((response) => {
          const { data } = response;
          accountData.value = data;
          studentData.value = data.student;
          profileData.value = data.profile;
          cohortData.value = data.cohort;

          toast({
            component: ToastificationContent,

            props: {
              variant: 'success',
              text: 'Successfully saved changes to account',
            },
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              accountData.value = { found: false };
              return;
            }
            toast({
              component: ToastificationContent,

              props: {
                variant: 'danger',
                title: 'Failed to save changes',
                text: error.response.data.message,
              },
            });

            console.error('Failed to fetch account', error);
            return;
          }

          toast({
            component: ToastificationContent,

            props: {
              variant: 'danger',
              title: 'Failed to save changes',
              text: 'An unknown error occurred',
            },
          });
        });
    };

    onMounted(() => {
      fetchAccount();
    });

    const totalDue = computed(() => {
      const account = accountData.value;
      if (!account || !account.charges) return 0;

      return account.charges.length > 0
        ? account.charges.map((charge) => Number(charge.amount)).reduce((a, b) => a + b, 0)
        : account.payments.filter((payment) => !payment.status === 'refunded').reduce((a, b) => a + Number(b.amount), 0);
    });
    const totalPaid = computed(() => {
      const account = accountData.value;
      if (!account || !account.payments) return 0;

      const totalRefunded = account.payments.filter((payment) => payment.status === 'refunded').reduce((a, b) => a + Number(b.amount), 0);

      return (
        account.payments.filter((payment) => ['paid', 'credited'].includes(payment.status)).reduce((a, b) => a + Number(b.amount), 0) -
        totalRefunded
      );
    });
    const totalBalance = computed(() => {
      const account = accountData.value;
      if (!account) return 0;

      return totalDue.value - totalPaid.value;
    });

    return {
      // accountData,
      clients: [],
      itemFormBlankCharge,
      itemFormBlankPayment,

      isLoading,
      fetchAccount,
      updateAccount,
      applyTemplate,

      statusOptions,
      paymentStatusOptions,
      paymentTypeOptions,
      paymentMethodOptions,
      paymentPayerOptions,

      accountData,
      studentData,
      profileData,
      cohortData,

      totalDue,
      totalPaid,
      totalBalance,

      payments,
      charges,

      moment,
      title,
    };
  },
};
</script>
